@import '../../assets/scss/variables.scss';

.eventdtl-body {
    @media screen and (max-width:767px) {
        padding: 0 !important;
    }

    .main-content_inner {
        background: none;
    }

    .stackable {
        .column.main-content-area {
            &.eventdtl-main-area {
                @media screen and (max-width:767px) {
                    padding: 0 !important;
                }
            }
        }
    }
}

.eventdetails-wrapper {
    font-family: $inter-font;
    color: #243B53;
    padding: 0 !important;

    .event-upper-card {
        border: 1px solid #DBE2EB;
        background: $white;
        border-radius: 14px;

        @media screen and (max-width:767px) {
            border-radius: 0;
            border: 0;
        }

        .event-matadata {
            position: relative;
            margin-bottom: 0.5rem;
        }

        .event-name {
            h1.name {
                font-size: 24px;
                font-weight: 700;
                color: #243B53;
                font-family: $inter-font;
                margin-bottom: 0.2rem;
            }

            .host {
                color: #486581;
                font-weight: 500;

                b {
                    font-weight: 600;

                    a {
                        color: #243B53;
                    }
                }
            }
        }

        .event-right-more {
            position: absolute;
            right: 0;

            .icon {
                width: 4px;
                fill: #243B53;
            }


                .item {
                    color: #334E68 !important;
                    font-size: 16px !important;
                    font-weight: 600;
                    border-bottom: 1px solid #DBE2EB;
                    padding: 15px !important;
                    cursor: pointer;

                    &:last-child {
                        border-bottom: 0;
                    }

                    &:hover {
                        color: #2166EE !important;
                    }
                }
        }

        .event-img {
            margin: auto;
            border-radius: 14px 14px 0 0;
            overflow: hidden;
            background: #E9EEF3;
            width: 100%;
            height: 280px;

            img {
                max-width: 100%;
                margin: auto;
                width: 100%;
                object-fit: fill;
            }

            .slick-slider {
                width: 100%;

                .slick-arrow {
                    background: $white;
                    width: 26px;
                    height: 26px;
                    border-radius: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        width: 8px;
                        fill: #1A1642;
                    }

                    &.slick-disabled {
                        opacity: 0.3;
                        cursor: default;
                    }
                }

                .slick-prev {
                    left: 15px;
                    z-index: 2;

                    &::before {
                        display: none;
                    }
                }

                .slick-next {
                    right: 15px;
                    z-index: 2;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .event-dtl {
            padding: 25px;

            @media screen and (max-width:576px) {
                padding: 20px;
            }
        }

        .event-datelocation {
            display: flex;
            align-items: flex-start;
            margin-top: 1.4rem;

            @media screen and (max-width:991px) {
                display: block;
            }

            .time-col {
                display: flex;
                width: 55%;

                @media screen and (max-width:991px) {
                    width: 100%;
                }

                .icon-col {
                    margin-right: 15px;

                    .icon {
                        fill: #334E68;
                    }
                }

                .time-list {
                    label {
                        color: #829AB1;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-family: $inter-font;
                        font-size: 12px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 600;
                        color: #334E68;
                        font-family: $inter-font;
                    }
                }
            }

            .location-col {
                margin-left: 0;
                font-weight: 700;
                color: #334E68;
                font-family: $inter-font;
                border-left: 1px solid $border-color-base;

                @media screen and (max-width:991px) {
                    margin-top: 1.5rem;
                    border-left: 0;
                    width: 100%;
                }

                label {
                    color: #829AB1;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: $inter-font;
                    font-size: 12px;
                    margin-right: 8px;
                }

                .row-1 {
                    display: flex;
                    align-items: center;
                    padding-left: 22px;
                    padding-bottom: 10px;

                    @media screen and (max-width:991px) {
                        border: 0;
                        padding-left: 0;
                    }

                    .icon {
                        margin-right: 15px;
                        fill: #334E68;

                        @media screen and (max-width:991px) {
                            margin-right: 15px;
                            width: 18px;
                        }
                    }
                }

                .locaton-name {
                    padding-left: 32px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #334E68;
                    font-family: $inter-font;
                }

                .row-2 {
                    padding-left: 20px;

                    @media screen and (max-width:991px) {
                        border: 0;
                        padding-left: 0;
                    }

                    .icon {
                        margin-right: 15px;
                        fill: #334E68;

                        @media screen and (max-width:991px) {
                            margin-right: 15px;
                            width: 18px;
                        }
                    }
                }

                .view-map {
                    padding-left: 52px;
                    margin-top: 6px;

                    @media screen and (max-width:991px) {
                        padding-left: 32px;
                    }
                }
            }
        }

        .event-option {
            margin-top: 1.2rem;

            .title {
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    color: #2166EE;
                }
            }

            ul {
                margin-top: 10px;

                @media screen and (max-width:640px) {
                    grid-gap: 10px;
                }

                li {
                    @media screen and (max-width:640px) {
                        margin: 0;
                    }

                    .button {
                        border: 1px solid #DBE2EB;
                        font-weight: 600;
                        font-size: 15px;
                        font-family: $inter-font;
                        background: $white;
                        border-radius: 6px;
                        color: #486581;

                        @media screen and (max-width:640px) {
                            font-size: 14px;
                        }
                    }

                    &.btn-active {
                        .button {
                            color: #2166EE;
                            background: #F3F7FF;
                            border-color: #F3F7FF;
                        }

                        &.nosure-button {
                            .button {
                                background: #FCF0F0;
                                border-color: #FCF0F0;
                                color: #CE0000;
                            }
                        }

                        &.maybe-button {
                            .button {
                                background: #DBE2EB;
                                border-color: #DBE2EB;
                                color: #486581;
                            }
                        }
                    }
                }
            }
        }
    }

    .event-bottom-details {
        border: 1px solid #DBE2EB;
        background: $white;
        border-radius: 14px;
        margin-top: 15px;

        @media screen and (max-width:767px) {
            border-radius: 0;
            border: 0;
        }

        .edit-booth {
            background: none;
            padding: 2px;

            .icon {
                fill: #829AB1;
                width: 16px;
                height: 16px;
                margin: 0 !important;
                opacity: 1;
            }

            &:hover {
                .icon {
                    fill: $primary-color;
                }
            }
        }

        .eventdetails-tab {
            font-family: $inter-font;

            .menu {
                margin-bottom: 0;
                padding: 0 22px;
                border-bottom: 1px solid #F1F1F1;
                margin-top: 10px;

                .item {
                    color: #334E68;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: $inter-font;
                    border-width: 3px;
                    padding: 10px;

                    &.active {
                        color: #2166EE !important;
                        border-color: #2166EE;
                        font-weight: 600;

                        &:hover {
                            border-color: #2166EE;
                        }
                    }

                    .label {
                        background: #486581;
                        border-radius: 6px;
                        font-family: $inter-font;
                        font-weight: 500;
                        margin: 0 0 -1px 4px !important;
                        padding: 3px 6px;
                        font-size: 12px;
                    }
                }
            }

            .tltle-secondary {
                color: #A3B2C6;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-top: 20px;
                display: block;
            }

            .mobileproduct-list {
                font-family: $inter-font;
                border-bottom: 1px solid #F1F1F1;
                padding: 14px 0;

                @media screen and (max-width:991px) {
                    padding-right: 28px;
                    position: relative;
                }

                b {
                    a {
                        font-size: 16px;
                        font-weight: 600;
                        color: #243B53;
                        margin-bottom: 5px;
                        display: block;
                    }
                }

                p {
                    font-size: 14px;
                    color: #486581;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .vanue-dtl {
                    display: flex;
                    color: #486581CC;
                    font-size: 14px;
                    font-weight: 500;
                }

                .mobileevent-bookmark {
                    @media screen and (max-width:991px) { 
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .bookmark-icon {
                        cursor: pointer;
                        fill: #829AB1;

                        &.filled {
                            fill: #2166EE;
                        }
                    }

                    .bookmark-icon-filled {
                        width: 24px;
                        cursor: pointer;
                    }
                }

            }

            .segment {
                border: 0;
                padding: 24px 20px;
            }

            .event-info {
                .about-event {
                    color: #486581;
                    margin-bottom: 1.5rem;

                    * {
                        font-family: $inter-font;
                    }
                }

                .detailsrow {
                    margin-bottom: 1.5rem;

                    .h3-title {
                        font-family: $inter-font;
                        font-size: 20px;
                        font-weight: 600;
                        color: #243B53;
                        margin-bottom: 3px;
                        display: block;
                    }

                    a {
                        color: #2166EE;
                        font-weight: 600;
                        word-break: break-all;
                    }

                    p {
                        font-size: 15px;
                        font-weight: 500;
                        font-family: $inter-font;
                        color: #486581;
                        margin-bottom: 0.2rem;
                    }
                }
            }

            .exhibitor-table {
                border: 1px solid #DBE2EB;
                border-radius: 6px;
                margin: 0;

                @media screen and (max-width:991px) {
                    border-collapse: collapse;
                    margin: 0;
                    width: 100%;
                    table-layout: fixed;
                }

                thead {
                    @media screen and (max-width:991px) {
                        border: none;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }

                    tr {
                        th {
                            background: #F6F8FC;
                            border-color: #DBE2EB;

                            b {
                                font-size: 15px;
                                color: #243B53;
                                font-weight: 600;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 1rem, 0.5rem;

                            @media screen and (max-width:991px) {
                                display: block !important;
                                text-align: left;
                                min-height: 48px;
                            }

                            .contact-label {
                                font-weight: 600;
                            }

                            .art-body-text {
                                line-height: 1.4;
                            }

                            .boothhall-label {
                                display: flex;
                                white-space: nowrap;
                                justify-content: center;
                                line-height: 1.1;
                            }

                            .companyproduct-col {
                                display: flex;
                                align-items: center;

                                @media screen and (max-width:991px) {
                                    flex-direction: column;
                                    align-items: flex-start;
                                }

                                .images {
                                    background: #E9EEF3;
                                    border-radius: 6px;
                                    width: 42px;
                                    height: 42px;
                                    min-width: 42px;
                                    margin-right: 10px;

                                    @media screen and (max-width:767px) {
                                        margin-right: 0;
                                        margin-bottom: 10px;
                                    }
                                }

                                p {
                                    color: #486581;
                                    font-size: 13px;
                                    font-weight: 500;
                                    font-family: $inter-font;
                                }

                                b {
                                    font-weight: 600;
                                    font-size: 15px;
                                    line-height: 1.2;
                                    color: #243B53;
                                    display: block;
                                    font-family: $inter-font;
                                }

                                b {
                                    a {
                                        font-weight: 600;
                                        font-size: 15px;
                                        color: #243B53;
                                        margin-bottom: 5px;
                                        font-family: $inter-font;
                                    }
                                }
                            }

                            &::before {
                                @media screen and (max-width:991px) {
                                    display: none;
                                }
                            }

                            label {
                                color: #243B53;
                            }
                        }

                        .bookmark-icon {
                            cursor: pointer;
                            fill: #829AB1;

                            &.filled {
                                fill: #2166EE;
                            }
                        }

                        .bookmark-icon-filled {
                            width: 24px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .header-row {
                .row {
                    display: flex;
                    margin: 0 -15px;
                }

                .showinterest-col {
                    text-align: right;

                    .button {
                        background: none;
                        padding: 0;
                        color: $primary-color;
                        background: none;
                        font-size: 13px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;
                        font-family: $inter-font;

                        .icon {
                            width: 17px;
                            height: 17px;
                            margin-right: 4px;
                            fill: #829AB1;
                        }
                    }

                    @media screen and (max-width:767px) {
                        text-align: left;
                    }
                }

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #243B53;
                    font-family: $inter-font;
                    margin-bottom: 4px;

                    @media screen and (max-width:767px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 14px;
                    color: #486581;
                    font-weight: 400;
                    font-family: $inter-font;
                    margin-bottom: 0;

                    @media screen and (max-width:767px) {
                        font-size: 14px;
                    }
                }

                .fiter-section {
                    display: flex;
                    align-items: center;
                    margin: 0.6rem 0 1.2rem 0;

                    @media screen and (max-width:767px) {
                        margin: 0.2rem 0 0.6rem 0;
                    }

                    .input {
                        max-width: 80%;
                        width: 100%;
                        height: 40px;

                        input {
                            background: #F6F8FC;
                            border: 1px solid #DBE2EB;
                            border-radius: 8px;
                        }
                    }

                    .button {
                        min-width: 115px;
                        background: #F6F8FC;
                        border: 1px solid #DBE2EB;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;
                        color: #A3B2C6;
                        font-size: 14px;
                        height: 40px;
                        margin-left: 8px;
                        font-family: $inter-font;
                        padding: 0 1rem;
                        margin-right: 0;

                        &.save-btn {
                            min-width: 90px;

                            @media screen and (max-width:640px) {
                                font-size: 0;
                                min-width: 40px;
                                padding: 0;
                                margin-left: 10px;
                            }

                            .icon {
                                fill: #829AB1;
                                width: 13px;
                            }
                        }

                        &.saved-btn {
                            color: #334E68;
                            font-weight: 500;

                            .icon {
                                fill: $primary-color;
                                opacity: 1;
                            }
                        }

                        @media screen and (max-width:640px) {
                            font-size: 0;
                            min-width: 40px;
                            padding: 0;
                            margin-left: 10px;
                        }

                        .icon {
                            width: 23px;
                            height: 23px;
                        }

                        &.filtered-btn {
                            color: #334E68;
                            font-weight: 500;
                        }

                        .label {
                            background: $primary-color;
                            color: $white;
                            margin: 0 5px 0 0 !important;
                            padding: 0.3233em 0.500em !important;
                            font-family: $inter-font;
                            font-weight: 500 !important;
                        }
                    }
                }
            }

            .loadmore-cell {
                text-align: left !important;
            }

            .load_more {
                text-align: center;
                padding: 0;
                font-size: 15px;
                font-weight: 600 !important;
                margin: 0 !important;
                font-family: $inter-font;
                color: #2166EE;
            }
        }
    }
}

.ui.modal.eventdtl-productfilter-modal {
    font-family: $inter-font;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 550px;
    border-radius: 15px !important;
    overflow: hidden;

    .header {
        position: relative;
        border-bottom: 1px solid #DBE2EB;

        .header-left {
            display: flex;
            align-items: center;

            .btn-list {
                display: flex;
                align-items: center;
                grid-gap: 8px;
                margin-left: 10px;

                button {
                    border: 1px solid #DBE2EB;
                    border-radius: 5px;
                    background: $white;
                    height: 30px;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 5px 12px;
                    color: #486581;
                    cursor: pointer;
                    font-family: $inter-font;

                    @media screen and (max-width:640px) {
                        font-size: 13px;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    &.active {
                        color: #2166EE;
                        border-color: #2166EE;
                    }
                }
            }

            .product-filter-loader:after {
                border-color: #000000 transparent transparent !important;
            }
        }

        h2 {
            font-size: 18px;
            font-weight: 700;
            color: #243B53;
            margin-bottom: 0;
            font-family: $inter-font;

            @media screen and (max-width:640px) {
                font-size: 16px;
            }
        }

        p {
            color: #486581;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
            font-family: $inter-font;
        }

        .close-button {
            position: absolute;
            background: none;
            border: 0;
            padding: 0;
            top: 25px;
            right: 15px;

            @media screen and (max-width:767px) {
                top: 20px;
            }

            .icon {
                fill: #334E68;
                margin: 0;
            }

            &:hover {
                .icon {
                    fill: #2166EE;
                }
            }
        }
    }

    .boothnumer-inner {
        label {
            display: block;
            color: #243B53;
            font-size: 15px;
            font-weight: 600;
            font-family: $inter-font;
        }

        .input {
            width: 100%;
            height: 40px;

            input {
                background: $white;
                border: 1px solid #DBE2EB;
                border-radius: 6px;
                font-family: $inter-font;
                color: #243B53;
            }
        }
    }

    .product-filter {
        padding: 0;

        .input {
            width: 100%;
            height: 40px;

            input {
                background: #F6F8FC;
                border: 1px solid #DBE2EB;
                border-radius: 8px;
                font-family: $inter-font;
                color: #243B53;
                font-weight: 500;

                &::placeholder {
                    color: #A3B2C6;
                }

                &::-webkit-placeholder {
                    color: #A3B2C6;
                }

                &::-moz-placeholder {
                    color: #A3B2C6;
                }

                font-weight: 500;
            }
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 1.4rem;
            max-height: calc(100vh - 220px);
            overflow-y: auto;

            li {
                padding: 10px 0;

                .checkbox {
                    label {
                        font-size: 14px;
                        font-weight: 500;
                        font-family: $inter-font;
                        color: #486581;
                        min-height: 23px;
                        margin-bottom: 0;
                        padding-left: 33px;

                        &::before {
                            border: 2px solid #829AB1;
                            width: 23px;
                            height: 23px;
                            border-radius: 5px;
                            margin-top: -3px;
                        }

                        &::after {
                            width: 23px;
                            height: 23px;
                            line-height: 23px;
                            margin-top: -3px;
                        }
                    }

                    &.checked {
                        label {
                            &::before {
                                background: #2166EE;
                                border-color: #2166EE;
                            }

                            &::after {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }


    }

    .actions {
        border-top: 1px solid #DBE2EB;
        padding: 1rem !important;

        @media screen and (max-width:640px) {
            padding: 0.7rem 1rem !important;
        }

        .default-btn {
            border: 1px solid #DBE2EB;
            font-weight: 600;
            background: $white;
            border-radius: 6px;
            color: #486581;
            font-family: $inter-font;
            margin-bottom: 0;
        }

        .button.primary {
            font-weight: 600 !important;
            border-radius: 6px;
            font-family: $inter-font;
        }

        &[textalign="center"] {
            text-align: center;
            border: 0;
            padding-bottom: 2rem;
        }
    }
}

.ui.modal.eventdtl-attendees-modal {
    font-family: $inter-font;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 550px;
    border-radius: 15px !important;
    overflow: hidden;

    .header {
        position: relative;
        border-bottom: 1px solid #DBE2EB;

        h2 {
            font-size: 18px;
            font-weight: 700;
            color: #243B53;
            margin-bottom: 5px;
            font-family: $inter-font;

            @media screen and (max-width:640px) {
                font-size: 16px;
            }
        }

        p {
            color: #486581;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
            font-family: $inter-font;

            @media screen and (max-width:640px) {
                font-size: 13px;
            }
        }

        .close-button {
            position: absolute;
            background: none;
            border: 0;
            padding: 0;
            top: 30px;
            right: 15px;

            .icon {
                fill: #334E68;
                margin: 0;
            }

            &:hover {
                .icon {
                    fill: #2166EE;
                }
            }
        }
    }

    .content {
        padding: 0 !important;
    }

    .eventdtl-list {
        padding: 0;

        ul {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 1.4rem;
            max-height: calc(100vh - 80px);
            overflow-y: auto;
            margin-top: 0;

            li {
                padding: 15px 20px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #DBE2EB;

                .avatar-img {
                    width: 42px;
                    height: 42px;
                    border-radius: 42px;
                    background: #E9EEF3;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                h3 {
                    margin-bottom: 0;

                    a {
                        font-size: 16px;
                        color: #334E68;
                        font-weight: 600;
                        margin-bottom: 0;
                        font-family: $inter-font;
                    }

                    margin-bottom: 0;

                    a {
                        font-size: 16px;
                        color: #334E68;
                        font-weight: 600;
                        margin-bottom: 0;
                        font-family: $inter-font;

                        @media screen and (max-width:640px) {
                            font-size: 15px;
                        }
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: #486581;
                    margin-bottom: 0;
                    font-family: $inter-font;

                    @media screen and (max-width:640px) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
